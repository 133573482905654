body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.jumbotron {
  background-image: url("./assets/images/Hapt.jpg");
  height:80vh;
  background-size: cover;
  background-position: center center;
  border-radius: 0px!important;
}


.jumbotron2 {
  background-image: url("./assets/images/Ali.jpg");
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron3 {
  background-image: url("./assets/images/X2.jpg");
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron4 {
  background-image: url("./assets/images/Garvey.jpg");
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron5 {
  background-image: url("./assets/images/Skepta.jpg");
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron6 {
  background-image: url("./assets/images/Denzel.jpg");
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron7 {
  background-image: url("./assets/images/Kendrick.jpg");
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron8 {
  background-image: url("./assets/images/King.jpg");
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron9 {
  background-image: url("./assets/images/Banana.jpg");
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron10 {
  background-image: url("./assets/images/Rawlings.jpg");
  height: 80vh;
  background-size: cover;
  background-position: center center;
}

.jumbotron11 {
  background-image: url("./assets/images/Thanks.JPG");
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron12 {
  background-image: url("./assets/images/Kobe.jpg");
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

@media only screen and (max-width: 992px) and (min-height:600px){
  .jumbotron {
    height: 45vh;
    width: 100%;
    background-position: center center;
  }
  .jumbotron2 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }
  .jumbotron3 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }
  .jumbotron4 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }
  .jumbotron5 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }
  .jumbotron6 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }
  .jumbotron7 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }
  .jumbotron8 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }

  .jumbotron9 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }

  .jumbotron10 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }

  .jumbotron11 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }

  .jumbotron12 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }

  .btn-sm {
    font-size: 6px;
  }

  .yt-embed {
    width: 280px;
  }
} 

.yt-embed {align-items:center; overflow:hidden; padding-bottom:56.25%; position:relative; height: 0px;}
 
.yt-video {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}
.pn-dark-grey {
  background: #262626;
}
.pn-darkest-grey {
  background: #333333;
}

.pn-gold {
  color: goldenrod;
}

.main-navigation{
  background-color: black;
  opacity: 0.5;
  box-shadow: 0 2px 4px -1px;
}

.pn-white{
  color: white;
}

.pn-white:hover {
  color: white;
}

.pn-bg-gold {
  background: gold;
}

.pn-section {
	max-width:1160px;
	margin:0 auto;
	padding:0% 2%;
}

.pn-grid {
	margin: 20px 0 0 0;
	padding: 0;
	list-style: none;
	display: block;
	text-align: center;
	width: 100%;
}

.pn-grid:after {
	clear: both;
}

.pn-grid:after,
.pn-box:before {
	content: '';
    display: table;
}

.pn-grid li {
	width: 320px;
	height: 320px;
	display: inline-block;
	margin: 20px;
}

.pn-box {
	width: 100%;
	height: 100%;
	position: relative;
	cursor: pointer;
    border-radius: 10px;
    -webkit-transition: 0.3s ease-in-out, 
    -webkit-transform 0.3s ease-in-out;
	-moz-transition:  0.3s ease-in-out, 
    -moz-transform 0.3s ease-in-out;
    transition: all 0.3s ease-in-out, 
    transform 0.3s ease-in-out, ;
}

.pn-box:hover {
	transform: scale(1.05); 
}

.img1 { 
 background: 
    linear-gradient(
      rgba(0, 0, 0, 0.50), 
      rgba(0, 0, 0, 0.50)
    ),
    /* bottom, image */
    url(./assets/images/episodes/1.jpg);
    background-size: cover;
}

.img2 { 
 background: 
    linear-gradient(
      rgba(0, 0, 0, 0.50), 
      rgba(0, 0, 0, 0.50)
    ),
    /* bottom, image */
    url(./assets/images/episodes/2.jpg);
    background-size: cover;
}

.img3 { 
 background: 
    linear-gradient(
      rgba(0, 0, 0, 0.50), 
      rgba(0, 0, 0, 0.50)
    ),
    /* bottom, image */
    url(./assets/images/episodes/3.jpg);
    background-size: cover;
}

.img4 { 
 background: 
    linear-gradient(
      rgba(0, 0, 0, 0.50), 
      rgba(0, 0, 0, 0.50)
    ),
    /* bottom, image */
    url(./assets/images/episodes/4.jpg);
    background-size: cover;
}

.img5 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/5.jpg);
     background-size: cover;
 }
 
 .img6 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/6.jpg);
     background-size: cover;
 }
 
 .img7 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/7.jpg);
     background-size: cover;
 }
 
 .img8 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/8.jpg);
     background-size: cover;
 }

 .img9 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/9.jpg);
     background-size: cover;
 }
 
 .img10 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/10.jpg);
     background-size: cover;
 }
 
 .img11 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/11.jpg);
     background-size: cover;
 }
 
 .img12 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/12.jpg);
     background-size: cover;
 }

 .bigform {
   height: 100px !important;
 }

 .img13 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/13.jpg);
     background-size: cover;
 }

 .img14 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/14.jpg);
     background-size: cover;
 }

 .img15 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/15.jpg);
     background-size: cover;
 }

 .img16 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/16.jpg);
     background-size: cover;
 }

 .img17 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/17.jpg);
     background-size: cover;
 }

 .img18 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/18.jpg);
     background-size: cover;
 }

 .img19 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/19.jpg);
     background-size: cover;
 }

 .img20 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/episodes/20.jpg);
     background-size: cover;
 }

.pn-info {
	position: absolute;
	width: inherit;
	height: inherit;
}

.pn-info h3 {
  font-weight: 400;
	color: #fff;
	font-size: 36px;
	margin: 0 30px;
	padding: 100px 0 0 0;
	line-height:1.5;
}

.pn-info p {
	color: #fff;
	padding: 4px 5px;
	margin: 0 30px;
	font-size: 14px;
	line-height:2;
}

.apply-img1 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/apply/Come-on-episode.jpg);
     background-size: cover;
 }
 
 .apply-img2 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/apply/male-bc.jpg);
     background-size: cover;
 }
 
 .apply-img3 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/apply/bh-bc.jpg);
     background-size: cover;
 }
 
 .apply-img4 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     /* bottom, image */
     url(./assets/images/apply/MaleAff.jpg);
     background-size: cover;
 }
 
 .apply-img5 { 
   background: 
      linear-gradient(
        rgba(0, 0, 0, 0.50), 
        rgba(0, 0, 0, 0.50)
      ),
      /* bottom, image */
      url(./assets/images/apply/FemaleAff.jpg);
      background-size: cover;
  }

  .apply-img6 { 
    background: 
       linear-gradient(
         rgba(0, 0, 0, 0.50), 
         rgba(0, 0, 0, 0.50)
       ),
       /* bottom, image */
       url(./assets/images/apply/aff-writer.jpg);
       background-size: cover;
   }
   
   .apply-img7 { 
    background: 
       linear-gradient(
         rgba(0, 0, 0, 0.50), 
         rgba(0, 0, 0, 0.50)
       ),
       /* bottom, image */
       url(./assets/images/apply/fem-host.jpg);
       background-size: cover;
   }
   
   .apply-img8 { 
    background: 
       linear-gradient(
         rgba(0, 0, 0, 0.50), 
         rgba(0, 0, 0, 0.50)
       ),
       /* bottom, image */
       url(./assets/images/apply/researcher.jpg);
       background-size: cover;
   }

  .pn-info h4 {
    font-weight: 400;
    color: #fff;
    font-size: 30px;
    margin: 0 30px;
    padding: 100px 0 0 0;
    line-height:1.5;
  }