body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.jumbotron {
  background-image: url(/static/media/Hapt.73de0409.jpg);
  height:80vh;
  background-size: cover;
  background-position: center center;
  border-radius: 0px!important;
}


.jumbotron2 {
  background-image: url(/static/media/Ali.9b82bf46.jpg);
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron3 {
  background-image: url(/static/media/X2.da400d5f.jpg);
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron4 {
  background-image: url(/static/media/Garvey.5f6396a1.jpg);
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron5 {
  background-image: url(/static/media/Skepta.77ae1342.jpg);
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron6 {
  background-image: url(/static/media/Denzel.8792920c.jpg);
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron7 {
  background-image: url(/static/media/Kendrick.59c275e1.jpg);
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron8 {
  background-image: url(/static/media/King.2aa30b42.jpg);
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron9 {
  background-image: url(/static/media/Banana.5593342d.jpg);
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron10 {
  background-image: url(/static/media/Rawlings.54057299.jpg);
  height: 80vh;
  background-size: cover;
  background-position: center center;
}

.jumbotron11 {
  background-image: url(/static/media/Thanks.cb8ca727.JPG);
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

.jumbotron12 {
  background-image: url(/static/media/Kobe.025e6743.jpg);
  height: 80vh;
  background-size: cover;
  border-radius: 0px!important;
  background-position: center center;
}

@media only screen and (max-width: 992px) and (min-height:600px){
  .jumbotron {
    height: 45vh;
    width: 100%;
    background-position: center center;
  }
  .jumbotron2 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }
  .jumbotron3 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }
  .jumbotron4 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }
  .jumbotron5 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }
  .jumbotron6 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }
  .jumbotron7 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }
  .jumbotron8 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }

  .jumbotron9 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }

  .jumbotron10 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }

  .jumbotron11 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }

  .jumbotron12 {
    height:45vh;
    width: 100%;
    background-position: center center;
  }

  .btn-sm {
    font-size: 6px;
  }

  .yt-embed {
    width: 280px;
  }
} 

.yt-embed {align-items:center; overflow:hidden; padding-bottom:56.25%; position:relative; height: 0px;}
 
.yt-video {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}
.pn-dark-grey {
  background: #262626;
}
.pn-darkest-grey {
  background: #333333;
}

.pn-gold {
  color: goldenrod;
}

.main-navigation{
  background-color: black;
  opacity: 0.5;
  box-shadow: 0 2px 4px -1px;
}

.pn-white{
  color: white;
}

.pn-white:hover {
  color: white;
}

.pn-bg-gold {
  background: gold;
}

.pn-section {
	max-width:1160px;
	margin:0 auto;
	padding:0% 2%;
}

.pn-grid {
	margin: 20px 0 0 0;
	padding: 0;
	list-style: none;
	display: block;
	text-align: center;
	width: 100%;
}

.pn-grid:after {
	clear: both;
}

.pn-grid:after,
.pn-box:before {
	content: '';
    display: table;
}

.pn-grid li {
	width: 320px;
	height: 320px;
	display: inline-block;
	margin: 20px;
}

.pn-box {
	width: 100%;
	height: 100%;
	position: relative;
	cursor: pointer;
    border-radius: 10px;
 transition: all 0.3s ease-in-out, 
    transform 0.3s ease-in-out;
}

.pn-box:hover {
	transform: scale(1.05); 
}

.img1 { 
 background: 
    linear-gradient(
      rgba(0, 0, 0, 0.50), 
      rgba(0, 0, 0, 0.50)
    ),
    
    url(/static/media/1.d034b6b5.jpg);
    background-size: cover;
}

.img2 { 
 background: 
    linear-gradient(
      rgba(0, 0, 0, 0.50), 
      rgba(0, 0, 0, 0.50)
    ),
    
    url(/static/media/2.8bcae7f4.jpg);
    background-size: cover;
}

.img3 { 
 background: 
    linear-gradient(
      rgba(0, 0, 0, 0.50), 
      rgba(0, 0, 0, 0.50)
    ),
    
    url(/static/media/3.22819230.jpg);
    background-size: cover;
}

.img4 { 
 background: 
    linear-gradient(
      rgba(0, 0, 0, 0.50), 
      rgba(0, 0, 0, 0.50)
    ),
    
    url(/static/media/4.7ee3942e.jpg);
    background-size: cover;
}

.img5 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/5.957a1a8a.jpg);
     background-size: cover;
 }
 
 .img6 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/6.cc898f83.jpg);
     background-size: cover;
 }
 
 .img7 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/7.c978e705.jpg);
     background-size: cover;
 }
 
 .img8 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/8.98365b09.jpg);
     background-size: cover;
 }

 .img9 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/9.976619f6.jpg);
     background-size: cover;
 }
 
 .img10 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/10.de32c537.jpg);
     background-size: cover;
 }
 
 .img11 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/11.c21317d4.jpg);
     background-size: cover;
 }
 
 .img12 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/12.8932c81a.jpg);
     background-size: cover;
 }

 .bigform {
   height: 100px !important;
 }

 .img13 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/13.4ced7e41.jpg);
     background-size: cover;
 }

 .img14 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/14.2a700604.jpg);
     background-size: cover;
 }

 .img15 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/15.95646ffa.jpg);
     background-size: cover;
 }

 .img16 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/16.1afccdb0.jpg);
     background-size: cover;
 }

 .img17 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/17.2d183c4f.jpg);
     background-size: cover;
 }

 .img18 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/18.2fc75d0c.jpg);
     background-size: cover;
 }

 .img19 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/19.9101de16.jpg);
     background-size: cover;
 }

 .img20 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/20.0b31b525.jpg);
     background-size: cover;
 }

.pn-info {
	position: absolute;
	width: inherit;
	height: inherit;
}

.pn-info h3 {
  font-weight: 400;
	color: #fff;
	font-size: 36px;
	margin: 0 30px;
	padding: 100px 0 0 0;
	line-height:1.5;
}

.pn-info p {
	color: #fff;
	padding: 4px 5px;
	margin: 0 30px;
	font-size: 14px;
	line-height:2;
}

.apply-img1 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/Come-on-episode.167c27c9.jpg);
     background-size: cover;
 }
 
 .apply-img2 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/male-bc.9d5dd5e3.jpg);
     background-size: cover;
 }
 
 .apply-img3 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/bh-bc.dfa221dd.jpg);
     background-size: cover;
 }
 
 .apply-img4 { 
  background: 
     linear-gradient(
       rgba(0, 0, 0, 0.50), 
       rgba(0, 0, 0, 0.50)
     ),
     
     url(/static/media/MaleAff.67ca1a8f.jpg);
     background-size: cover;
 }
 
 .apply-img5 { 
   background: 
      linear-gradient(
        rgba(0, 0, 0, 0.50), 
        rgba(0, 0, 0, 0.50)
      ),
      
      url(/static/media/FemaleAff.56f3fb2d.jpg);
      background-size: cover;
  }

  .apply-img6 { 
    background: 
       linear-gradient(
         rgba(0, 0, 0, 0.50), 
         rgba(0, 0, 0, 0.50)
       ),
       
       url(/static/media/aff-writer.5df14b1f.jpg);
       background-size: cover;
   }
   
   .apply-img7 { 
    background: 
       linear-gradient(
         rgba(0, 0, 0, 0.50), 
         rgba(0, 0, 0, 0.50)
       ),
       
       url(/static/media/fem-host.477dfb7e.jpg);
       background-size: cover;
   }
   
   .apply-img8 { 
    background: 
       linear-gradient(
         rgba(0, 0, 0, 0.50), 
         rgba(0, 0, 0, 0.50)
       ),
       
       url(/static/media/researcher.75ada782.jpg);
       background-size: cover;
   }

  .pn-info h4 {
    font-weight: 400;
    color: #fff;
    font-size: 30px;
    margin: 0 30px;
    padding: 100px 0 0 0;
    line-height:1.5;
  }
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

